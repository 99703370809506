<template>
  <div class='flex flex-col lg:flex-row justify-between items-stretch gap-x-16'
    :style='minHeightStyle'>
    <div
      class='background-style text-white py-4 px-4 lg:py-16 lg:px-8 flex-shrink-0'
      :style='sidebarBgStyle'>
      <ul class='text-xs sidebar-navigation-links flex flex-col lg:sticky lg:top-24'>
        <li v-for='(sidebarLink, index) in sidebarLinks'
          :key='`sidebar-link-${sidebarLink.title}-${index}`'
          class='mb-2 lg:mb-4 border border-transparent hover:border-gray-100 w-full rounded-md'>
          <router-link v-if='sidebarLink.actionType==="open_internal_route"'
            :to='{ name: sidebarLink.link }'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </router-link>
          <a v-else-if='sidebarLink.actionType==="open_external_link"'
            :href='sidebarLink.link'
            class='text-lg py-2 pl-2 pr-1 uppercase opacity-90 flex flex-col-reverse lg:flex-row justify-start gap-y-2 lg:gap-y-0 lg:gap-x-2'>
            {{ sidebarLink.title }}
          </a>
        </li>
      </ul>
    </div>
    <div class='flex-grow relative px-4 lg:px-0'>
      <welcome-conference-banner v-if='showConferenceBanner' class='mb-8 lg:mb-12' />
      <div v-if='alreadyRegisteredMyself' class='py-8 lg:py-16'>
        <registration-done-message
         class='max-w-2xl bg-gray-100 rounded-md p-16'
         :is-korean='true' />
      </div>
      <div class='py-8 lg:py-16' v-else-if='isRegistrationPeriod'>
        <h1 class='text-3xl font-bold mb-4 lg:mb-8'>{{ registrationFormBoxTitle }}</h1>
        <div v-if='registrationFormBoxHtmlContent' v-html='registrationFormBoxHtmlContent'></div>
        <div v-for='formField in registrationFormFields'
          :key='formField.keyName'
          class='mb-6'>
          <edit-form-field
            class='w-full ' 
            :edit-form-field='formField'
            :value='fieldValue(formField.category, formField.keyName)'
            :show-requirement-indicator-on-top='true'
            @update-edit-form-field-value='updateRegistrationFormFieldValue(formField.category, formField.keyName, ...arguments)' />
        </div>
        <!-- make component by event? -->
        <div class='border rounded-t-md bg-gray-50 p-4 w-full lg:w-1/2 mb-6'>
          <h2 class='text-lg font-semibold mb-4 text-gray-900'>{{ registrationFeeBoxTitle }}</h2>
          <div class='flex flex-row justify-between mb-2 text-gray-900'>
            <h4>등록비</h4><p> {{ formattedFeeAmount(registrationFeeAmount) }}</p>
          </div>
          <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
            <h4>계좌 정보</h4>
            <p class='text-gray-900 rounded font-semibold'>하나은행 584-910003-16504</p>
          </div>
          <div class='flex flex-row justify-between mb-2' v-if='paymentGateway === "bank_transfer"'>
            <h4>예금주</h4>
            <p>대한비만학회-등록비</p>
          </div>
        </div>
        <!--  -->
        <div v-for='(term, index) in terms'
          :key='`term-${index}`'
          class='text-xs border rounded-md mb-8 w-full lg:w-1/2'>
          <div class='bg-gray-50 p-4 h-40 overflow-y-auto' v-html='term.text'></div>
          <label class='border-t p-4 flex flex-row justify-start items-center gap-x-0.5 text-sm'>
            <input type='checkbox' :name='`checkbox-${index}`' v-model='term.agreed'>
            <span class='px-1' v-html='term.confirmationText'></span>
          </label>
        </div>
        <button 
          class='px-4 py-3 hover:shadow-md uppercase border rounded-md submit-button w-full lg:w-1/2 mb-6'
          @click='registerConference'
          :disabled='disabledRegistrationButton'
          :class='disabledRegistrationButtonClass'>
          {{ registrationButtonText }}
        </button>
      </div>
      <div v-else v-html='registrationPeriodOverHtmlContent'>
      </div>
      <welcome-sponsor-carousel  v-if='showSponsorCarousel'  class='max-w-4xl mt-16 lg:mt-20'/>
    </div>
    <modal
      name='registration-payment-modal'
      :adaptive='true'
      :click-to-close='canClickToCloseRegistrationModal'
      height='auto'
      :scrollable='true'
      classes='relative'
      @opened='initializePaymentModal'
      @closed='redirectIfFinished'>
      <button v-if='canClickToCloseRegistrationModal'
        class='z-30 absolute top-3 right-3' 
        @click="$modal.hide('registration-payment-modal')">
        <x-icon class='text-black' />
      </button>
      <!-- make component by event? -->
      <div v-if='registrationPaymentModalComponent == "bank-transfer"'>
        <div class='p-8 lg:p-16 overflow-y-auto flex-grow'>
          <div class='max-w-2xl'>
            <h1 class='text-2xl font-semibold mb-8'>제 57차 대한비만학회 춘계학술대회에 등록해 주셔서 감사합니다.</h1>
            <div class='px-12 py-8 rounded' style='background-color: #FAFAFA;'>
              <ul class='list-disc'>
                <li class='py-1'>등록비: {{ formattedFeeAmount(registrationFeeAmount) }}</li>
                <li class='py-1'>아래의 계좌로 입금하여 주십시오.</li>
                <li class='py-1'>입금자와 등록자의 성함이 다를 경우, 등록 확인이 불가하니 <span class='font-semibold text-red-600'>반드시 등록자의 성함으로 입금 부탁드립니다.</span></li>
                <li class='py-1'>등록 완료는 결제까지 완료된 상태이며, 사전등록 기간 내 미입금 시 등록이 취소될 수 있음을 안내해 드립니다.</li>
                <li class='py-1'><span class='font-semibold text-red-600'>입금완료 및 등록 완료 확인까지 시일이 소요</span>될 수 있으며, 빠른 확인이 필요하시면 사무국으로 연락 부탁드립니다. (<a href='mailto:kssoasc2023@into-on.com' class='underline' :style='themeTextColorStyle'>kssoasc2023@into-on.com</a>)</li>
                <li class='py-1'>계좌 정보: <span class='inline-block p-2 bg-gray-200 text-gray-900 rounded font-semibold'>하나은행 584-910003-16504</span>
                <li class='py-1'>예금주: 대한비만학회-등록비</li>
              </ul>
            </div>
            <router-link :to='{name: "Home"}' class='block mt-8 w-full lg:w-48 text-center bg-gray-900 text-white py-3 px-2 rounded-md hover:shadow-lg text-sm'>홈으로 가기</router-link>
          </div>
        </div>
      </div>
      <!--  -->
      <nhnkcp-payment v-else-if='registrationPaymentModalComponent == "nhnkcp-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='true'
        :is-korean='true'
        @payment-success='paymentSuccess'
        @payment-fail='paymentFail' />
      <iamport-payment v-else-if='registrationPaymentModalComponent == "iamport-payment"'
        :payment-params-prop='paymentParams'
        :is-modal-payment='true'
        :is-single-order='true'
        :is-korean='true'
        @payment-success='paymentSuccess'
        @payment-fail='paymentFail' />
      <payment-fail v-else-if='registrationPaymentModalComponent == "payment-fail"'
        :is-modal-payment='true'
        :is-korean='true' />
      <payment-success v-else-if='registrationPaymentModalComponent == "payment-success"'
        :payment-params-prop='paymentSuccessParams'
        :is-modal-payment='true'
        :is-korean='true'
        @payment-finished='paymentFinished'
        @payment-fail='paymentFail' />
      <registration-done-message v-else-if='registrationPaymentModalComponent == "registration-done-message"'
        class='max-w-2xl bg-gray-100 rounded-md p-16' 
        :is-korean='true' />
    </modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { XIcon } from '@vue-hero-icons/outline'
import numbro    from 'numbro'
import cloneDeep from 'lodash/cloneDeep'
import camelCase from 'lodash/camelCase'
import jsonLogic from 'json-logic-js'
import { mapFields } from 'vuex-map-fields'
import dayjs from 'dayjs'

export default {
  name: 'Registration',
  components: {
    XIcon,
    'registration-done-message': () => import('@/components/iamport-payment/RegistrationDoneMessage.vue'), 
    'edit-form-field': () => import('@/components/edit-form/EditFormField.vue'), 
    'welcome-conference-banner': () => import('@/components/welcome/WelcomeConferenceBanner.vue'),
    'welcome-sponsor-carousel': () => import('@/components/welcome/WelcomeSponsorsCarousel.vue'),
    'iamport-payment' : () => import( '@/components/iamport-payment/IamportPayment.vue'),
    'nhnkcp-payment' : () => import( '@/components/nhnkcp-payment/NhnkcpPayment.vue'),
    'payment-fail' : () => import( '@/components/payments/PaymentFail.vue'),
    'payment-success' : () => import( '@/components/payments/PaymentSuccess.vue'),
  },
  props: {
    showConferenceBanner: {
      type: Boolean,
      default: false,
    },
    showSponsorCarousel: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      terms: null,
      profile: null,
      user: null,
      membershipData: null,
      isValidPhoneNumber: false,
      paymentParams: null,
      registrationPaymentModalComponent: '',
      paymentSuccessParams: null,
      paymentFailParams: null,
    }
  },
  watch: {
    'termsDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.terms = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'userDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.user = cloneDeep(newVal)
        }
      },
      immediate: true,
      deep: true
    },
    'profileDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.profile = cloneDeep(newVal)
          this.getProfile().then((resp) => {
            Object.keys(this.profile).filter(key => key !== 'licenseNumber').forEach(key => {
              if (key === 'extraInfo') {
                this.profile[key] = {
                  medicalProfession: ''
                }
              } else {
                this.profile[key] = resp[key]
              }
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
    'registrationDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.getRegistrationData().then(() => {
            if (!this.alreadyRegisteredMyself) {
              this.registrationData = cloneDeep(newVal)
            }
          })
        }
      },
      immediate: true,
      deep: true
    },
    'membershipDataDefault': {
      handler: function (newVal, oldVal) {
        if (newVal && newVal !== oldVal) {
          this.membershipData = cloneDeep(newVal)
          this.getMembershipData().then(resp => {
            Object.keys(this.membershipData).forEach(key => {
              this.membershipData[key] = resp[key]
            })
          })
        }
      },
      immediate: true,
      deep: true
    },
  },
  computed: {
    ...mapFields('users', [
      'registrationData',
    ]),
    ...mapState('users', [
      'hasValidToken',
    ]),
    ...mapState('products', [
      'products',
    ]),
    ...mapGetters('events', [
      'showingEventId',
      'showingEventRegistrationConfigurations',
      'eventMainThemeColor',
      'themeTextColorStyle',
    ]),
    sidebarLinks () {
      return [
        {
          "link": "PreregistrationInformation",
          "title": "등록 안내",
          "actionType": "open_internal_route"
        },
        {
          "link": "ScoringCredits",
          "title": "평점 안내",
          "actionType": "open_internal_route"
        },
        {
          "link": "Registration",
          "title": "온라인 사전등록",
          "actionType": "open_internal_route"
        },
      ]
    },
    customBackgroundStyle () {
      return "background-color: #926699; background-image: url(https://webconcert-public-assets.s3.ap-northeast-2.amazonaws.com/conferences/kossoasc/sidebar_image_v2.png); background-repeat: no-repeat; background-position: top; background-size: 100% auto;"
    },
    sidebarBgStyle () {
      return (this.customBackgroundStyle) ? this.customBackgroundStyle : `background-color: ${this.eventMainThemeColor};`
    },
    minHeightStyle () {
      let minHeight = 64 // size of the navbar
      return `min-height: calc(100vh - ${minHeight}px);`
    },
    isKorean () {
      return !this.profile.country ||
             this.profile.country.toLowerCase() === 'kr'
    },
    registrationFormBoxTitle () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFormBoxTitle) ? this.showingEventRegistrationConfigurations.registrationFormBoxTitle : 'My Registration Details'
    },
    registrationFormBoxHtmlContent () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFormBoxHtmlContent) ? this.showingEventRegistrationConfigurations.registrationFormBoxHtmlContent : ''
    },
    registrationFeeBoxTitle () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFeeBoxTitle) ? this.showingEventRegistrationConfigurations.registrationFeeBoxTitle : 'Registration Type & Fee'
    },
    registrationButtonText () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationButtonText) ? this.showingEventRegistrationConfigurations.registrationButtonText : 'Register & Pay Registration Fees'
    },
    userDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.userDefault) ? this.showingEventRegistrationConfigurations.userDefault : {}
    },
    termsDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.termsDefault) ? this.showingEventRegistrationConfigurations.termsDefault : {}
    },
    profileDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.profileDefault) ? this.showingEventRegistrationConfigurations.profileDefault : {}
    },
    registrationDataDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationDataDefault) ? this.showingEventRegistrationConfigurations.registrationDataDefault : {}
    },
    membershipDataDefault () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.membershipDataDefault) ? this.showingEventRegistrationConfigurations.membershipDataDefault : {}
    },
    registrationDeadline () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationDeadline) ? this.showingEventRegistrationConfigurations.registrationDeadline : ''
    },
    registrationPeriodOverHtmlContent () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationPeriodOverHtmlContent) ? this.showingEventRegistrationConfigurations.registrationPeriodOverHtmlContent : '등록기간이 아닙니다.'
    },
    isRegistrationPeriod () {
      return !this.registrationDeadline ||
             (dayjs(this.registrationDeadline).isAfter(dayjs(new Date())) && this.registrationDeadline)
    },
    currentDateTimeString () {
      return dayjs().format('YYYYMMDDHHmm')
    },
    registrationFormFields () {
      return (this.showingEventRegistrationConfigurations && this.showingEventRegistrationConfigurations.registrationFormFields) ?
        this.showingEventRegistrationConfigurations.registrationFormFields.concat()
        .filter((field) => {
          let condition = field.metaData.showCondition ? field.metaData.showCondition : true
          return jsonLogic.apply(condition, {registrationData: this.registrationData, profile: this.profile, currentDateTime: this.currentDateTimeString})
        })
        .sort((fieldA, fieldB) => fieldA.sortOrder - fieldB.sortOrder) : []
    },
    // custom for kossoasc
    isKossoascMember () {
      return this.membershipData ? this.membershipData.isKossoascMember: ''
    },
    medicalProfession () {
      return this.profile ? this.profile.extraInfo.medicalProfession : ''
    },
    paymentGateway () {
      return this.registrationData ? this.registrationData.paymentGateway : ''
    },
    alreadyRegisteredMyself () {
      return this.registrationData.payment
    },
    registrationType () {
      return this.registrationData.registrationType
    },
    // custom for kossoasc
    registrationFeeAmount () {
      return this.registrationProduct ? this.registrationProduct.price.amount : 0
    },
    registrationProduct () {
      if (this.registrationType === '일반 참석자') {
        if (this.isKossoascMember === '회원') {
          if (['교수', '개원의', '봉직의', '교직의'].includes(this.medicalProfession)) {
            return this.products.find(product => product.name === 'icola-member50000')
          } else if (['전임의', '수련의', '전공의', '영양사', '임상영양사', '운동사', '간호사', '연구원', '기타'].includes(this.medicalProfession)) {
            return this.products.find(product => product.name === 'icola-member30000')
          } else {
            return this.products.find(product => product.name === 'icola-free')
          }
        } else if (this.isKossoascMember === '비회원') {
          if (['교수', '개원의', '봉직의', '교직의'].includes(this.medicalProfession)) {
            return this.products.find(product => product.name === 'icola-notMember80000')
          } else if (['전임의', '수련의', '전공의', '영양사', '임상영양사', '운동사', '간호사', '연구원', '기타'].includes(this.medicalProfession)) {
            return this.products.find(product => product.name === 'icola-notMember60000')
          } else {
            return this.products.find(product => product.name === 'icola-free')
          }
        } else {
          return this.products.find(product => product.name === 'icola-free')
        }
      } else {
        return this.products.find(product => product.name === 'icola-free')
      }
    },
    disabledRegistrationButton () {
      return this.registrationFormFields.map(field => this.fieldValue(field.category, field.keyName)).some(value => !value || !value.length) ||
             !this.terms.filter(term => term.required).every(term => term.agreed)
    },
    disabledRegistrationButtonClass () {
      return this.disabledRegistrationButton ? 'opacity-50 cursor-not-allowed' : ''
    },
    canClickToCloseRegistrationModal () {
      return this.registrationPaymentModalComponent == 'payment-fail'
    },
  },
  methods: {
    ...mapActions('users', [
      'getProfile',
      'getMembershipData',
      'getRegistrationData',
      'patchProfile',
      'patchRegistrationData',
      'checkTokenStatus',
    ]),
    ...mapActions('products', [
      'getAllProducts',
    ]),
    ...mapActions('orders', [
      'createOrder',
    ]),
    ...mapActions('registrations', [
      'sendRegistrationEmails'
    ]),
    fieldValue (category, keyName) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      let val = ''
      if (category === 'profile') {
        if (arr.length === 2) {
          val = this.profile[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          if (keyName === 'address') {
            val = `${this.profile.address} (${this.profile.postalCode})`
          } else {
            val = this.profile[arr[0]]
          }
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          val = this.user[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.user[arr[0]]
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          val = this.registrationData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.registrationData[arr[0]]
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          val = this.membershipData[arr[0]][arr[1]]
        } else if (arr.length === 1) {
          val = this.membershipData[arr[0]]
        }
      }
      return val
    },
    updateRegistrationFormFieldValue (category, keyName, value) {
      let arr = keyName.split('.').map(keyName => camelCase(keyName))
      if (category === 'profile') {
        if (arr.length === 2) {
          this.profile[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.profile[arr[0]] = value
        }
      } else if (category === 'user') {
        if (arr.length === 2) {
          this.user[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.user[arr[0]] = value
        }
      } else if (category === 'registration_data') {
        if (arr.length === 2) {
          this.registrationData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.registrationData[arr[0]] = value
        }
      } else if (category === 'membership_data') {
        if (arr.length === 2) {
          this.membershipData[arr[0]][arr[1]] = value
        } else if (arr.length === 1) {
          this.membershipData[arr[0]] = value
        }
      }
    },
    formattedFeeAmount (feeAmount) {
      return feeAmount ? `${numbro(feeAmount).format({
        thousandSeparated: true,
        mantissa: 0,
      })} 원` : '무료'
    },
    updateUserPaidData () {
      this.patchRegistrationData({ payment: true, paidAt: new Date(), })
    },
    registerConference () {
      let patchProfile = this.patchProfile(this.profile)
      this.registrationData.registrationFee = this.formattedFeeAmount(this.registrationFeeAmount)
      let patchRegistrationData = this.patchRegistrationData(this.registrationData)

      Promise.all([patchProfile, patchRegistrationData]).then(() => {
        this.createOrder({product: {
          product_id: this.registrationProduct.id,
          payment_type: this.paymentGateway,
          event_id: this.showingEventId}
        }).then((order) => {
          if (order.status === 'Done') {
            this.updateUserPaidData()
          } else {
            this.paymentParams = {
              pg:           this.paymentGateway,
              currency:     this.registrationProduct.price.priceUnit,
              amount:       order.price.amount,
              orderId:      order.hashedOrderId,
              orderName:    order.orderName,
              customerName: order.customerName ,
              customerPhoneNumber: this.profile.phoneNumber,
            }
            this.openRegistrationPaymentModal()
          }
          if (this.paymentGateway === 'bank_transfer') {
            this.sendRegistrationEmails({ email_type: 'myself_pending' })
          }
        }).catch(() => {
          this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
            type: 'warning'
          })
        })
      }).catch((error) => {
        console.error(error)
        this.$alert('에러가 발생했습니다. 지속 발생 시, 고객센터로 문의 부탁드립니다.', {
          type: 'warning'
        })
      })
    },
    initializePaymentModal () {
      if (this.paymentGateway === 'bank_transfer') {
        this.registrationPaymentModalComponent = 'bank-transfer'
      } else if (this.paymentGateway.includes('iamport')) {
        this.registrationPaymentModalComponent = 'iamport-payment'
      } else if (this.paymentGateway.includes('nhnkcp')) {
        this.registrationPaymentModalComponent = 'nhnkcp-payment'
      }
    },
    paymentFail (params) {
      // show payment fail with params
      this.paymentFailParams = params
      this.registrationPaymentModalComponent = 'payment-fail'
    },
    paymentSuccess (params) {
      // show payment success with params
      this.paymentSuccessParams = params
      this.registrationPaymentModalComponent = 'payment-success'
    },
    paymentFinished () {
      // show registration done message
      this.registrationPaymentModalComponent = 'registration-done-message'
      // payment done
      this.getRegistrationData().then(() => {
        if (!this.alreadyRegisteredMyself) {
          this.updateUserPaidData()
        }
      })
    },
    redirectIfFinished () {
      if (this.registrationPaymentModalComponent == 'registration-done-message') {
        this.$router.push({name: 'Main'})
      }
    },
    openRegistrationPaymentModal () {
      this.$modal.show('registration-payment-modal')
    },
    closeRegistrationPaymentModal () {
      this.$modal.hide('registration-payment-modal')
    },
  },
  created () {
    this.checkTokenStatus().then(() => {
      if (!this.hasValidToken) {
        this.$router.replace({name: 'Login'}).catch(() => {})
      }
    })
  },
  mounted () {
    this.getAllProducts()
  },
  beforeDestroy () {
    this.closeRegistrationPaymentModal()
  },
}
</script>

<style type='scss' scoped>
.submit-button {
  @apply text-white;
  background-color: var(--eventMainColor);
}

.background-style {
  background: transparent; 
  width: 100%;
}

.sidebar-navigation-links .router-link-active {
  @apply font-bold border rounded-md;
}
    
@media (min-width: 1024px) {
  .background-style {
    width: 20rem;
    height: auto;
  }
}
</style>
su